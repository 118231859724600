<template>
    <div>
        <module-form-header :label="$t('patient.consent.add')" :patient="info" :data="{}" />
        <div class="p-4">
            <consent-form
                :info="info"
                :data="{}"
                @consent-add="consentAdd"
                @close="$modal.hide('consent-add')"
            />
        </div>
    </div>
</template>

<script>
import ConsentForm from "./ConsentForm";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";

export default {
    name: "ConsentAdd",
    props: ["info"],
    components: {
        ModuleFormHeader,
        ConsentForm
    },

    methods: {
        async consentAdd(consent) {
            consent.status = parseInt(consent.status);
            consent.revocation_reason = parseInt(consent.revocation_reason);

            this.$store.dispatch("patient/addConsent", consent).then(() => {
                this.$modal.hide("consent-add");
            });
        },
    },
}
</script>