<template>
    <validation-observer :ref="'formobserver'" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(save)" class="cm-form h-full relative p-4"  v-if="loaded">
            <div>
                <div class="mr-4 flex flex-col md:flex-row"  v-if="!data.id">
                    <div class="lg:w-2/5">
                        <label class="block text-base">{{ $t("patient.consent.study") }}</label>
                    </div>
                    <div class="lg:w-3/5 modal">
                        <validation-provider v-slot="validation" rules="required" :name="$t('patient.consent.study')">
                            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                                {{ validation.errors[0] }}
                            </p>
                            <cm-search-select
                                ref="study_id"
                                id="study"
                                name="name"
                                valueProp="id"
                                :placeholder="$t('patient.consent.selectStudy')"
                                v-model="consent.study_id"
                                :selected="consent.study_id"
                                @select="consent.study_id = $event"
                                :options="selectableStudies"
                                :style="`width: ${searchSelectWidth}px;`"
                            />
                        </validation-provider>
                    </div>
                </div>

                <div class="mr-4 flex flex-col md:flex-row">
                    <div class="lg:w-2/5">
                        <label class="block text-base">{{ $t("patient.consent.consent") }}</label>
                    </div>
                    <div class="lg:w-3/5">
                        <validation-provider v-slot="validation" rules="required" :name="$t('patient.consent.consent') ">
                            <div >
                                <cm-select
                                    id="status"
                                    name="status"
                                    v-model="consent.status"
                                    :options="consentOptions"
                                    @change="statusChange"
                                />
                            </div>
                            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                                {{ validation.errors[0] }}
                            </p>
                        </validation-provider>
                    </div>
                </div>

                <div class="mr-4 flex flex-col md:flex-row" v-show="consent.status == 1">
                    <div class="lg:w-2/5">
                        <label class="block text-base">{{ $t("patient.consent.consent_date") }}</label>
                    </div>
                    <div class="lg:w-3/5">
                        <validation-provider v-slot="validation" :rules="consentRules" :name="$t('patient.consent.consent_date')">
                            <cm-datepicker2
                                id="consented_at"
                                name="consented_at"
                                :min-date="minConsentDate"
                                :max-date="maxConsentDate"
                                v-model="consent.consented_at"
                            />
                            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                                {{ validation.errors[0] }}
                            </p>
                        </validation-provider>
                    </div>
                </div>

                <div class="mr-4 flex flex-col md:flex-row" v-show="consent.status == 1">
                    <div class="lg:w-2/5">
                        <label class="block text-base">{{ $t("patient.consent.consent_name") }}</label>
                    </div>
                    <div class="lg:w-3/5">
                        <validation-provider v-slot="validation" :rules="consentRules" :name="$t('patient.consent.consent_name')">
                            <cm-input-text
                                type="text"
                                autocomplete="off"
                                id="consent_name"
                                name="consent_name"
                                v-model="consent.consented_by"
                            />
                            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                                {{ validation.errors[0] }}
                            </p>
                        </validation-provider>
                    </div>
                </div>

                <div class="mr-4 flex flex-col md:flex-row" v-show="(data.status == 1 || (data.status == 0 && data.revocation_reason)) && consent.status == 0">
                    <div class="lg:w-2/5">
                        <label class="block text-base">{{ $t("patient.consent.revocation_reason") }}</label>
                    </div>
                    <div class="lg:w-3/5">
                        <validation-provider v-slot="validation" :rules="revokedRules" :name="$t('patient.consent.revocation_reason')">
                            <cm-select
                                id="revocation_reason"
                                name="revocation_reason"
                                v-model="consent.revocation_reason"
                                :options="revokedOptions"
                            />
                            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                                {{ validation.errors[0] }}
                            </p>
                        </validation-provider>
                    </div>
                </div>

                <div class="mr-4 flex flex-col md:flex-row" v-show="(data.status == 1 || (data.status == 0 && data.revocation_reason)) && consent.status == 0">
                    <div class="lg:w-2/5">
                        <label class="block text-base">{{ $t("patient.consent.revoked_at") }}</label>
                    </div>
                    <div class="lg:w-3/5">
                        <validation-provider v-slot="validation" :rules="revokedRules" :name="$t('patient.consent.revoked_at')">
                            <cm-datepicker2
                                display="block"
                                id="revoked_at"
                                name="revoked_at"
                                v-model="consent.revoked_at"
                                :min-date="minRevokedDate"
                                :meta="{ mandatory: false }"
                            />
                            <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                                {{ validation.errors[0] }}
                            </p>
                        </validation-provider>
                    </div>
                </div>
            </div>
            <div class="pt-4">
                <cm-button type="submit" :disabled="invalid" size="medium">
                    {{ $t("button.save") }}
                </cm-button>
                <cm-button type="button" @click="close">
                    {{ $t("button.cancel") }}
                </cm-button>
                <cm-button v-if="data.study" visual="danger" @click="consentDelete">
                    {{ $t("button.delete") }}
                </cm-button>
            </div>
        </form>
    </validation-observer>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver, ValidationProvider, localize, extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules"

extend("required", required);
extend("regex", regex);

function loadVeeValidateLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

export default {
    name: "ConsentForm",
    props: ["info", "data"],
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    watch: {
        loaded: "setSearchSelectWidth",
    },
    data() {
        return {
            loaded: false,
            searchSelectWidth: null,
            consent: {
                id: null,
                study_id: null,
                status: null,
                consented_at: null,
                consented_by: null,
                revocation_reason: null,
                revoked_at: null
            },
        };
    },
    created() {
        this.load();
    },
    beforeMount() {
        loadVeeValidateLocale(this.userLocale ?? "sv");
    },
    computed: {
        ...mapState("meta", {
            studies: state => state.studies
        }),
        consentOptions() {
            return [
                { value: 99, translation: { label: this.$t("patient.consent.options.status.not_obtained") } },
                { value: 1, translation: { label: this.$t("patient.consent.options.status.yes") } },
                { value: 0, translation: { label: this.$t("patient.consent.options.status.no") } },
            ];
        },
        revokedOptions() {
            return [
                { value: 10, translation: { label: this.$t("patient.consent.options.revoked.do_not_want_to_participate") } },
                { value: 20, translation: { label: this.$t("patient.consent.options.revoked.deceased") } },
                { value: 99, translation: { label: this.$t("patient.consent.options.revoked.other") } }
            ]
        },
        selectableStudies() {
            let selectableStudies = [];
            let patientStudies = this.info.consents.filter(d => d.study).map( function(c) { //Get studies that the patient already have a relationship to
                return c.study.id;
            });

            this.studies.forEach(s => {
                if (patientStudies.indexOf(s.id) == -1) { //Exclude studies that patient already have a relationship to
                    selectableStudies.push(s);
                }
            });

            return selectableStudies;
        },
        minConsentDate() {
            var date = new Date();
            this.info.diagnoses.map(function (d) {
                let diagnosisDate = new Date(d.date);

                switch(d.date_approx) {
                    case "Y":
                        diagnosisDate.setMonth(0, 1);
                        break;
                    case "M":
                        diagnosisDate.setDate(1);
                        break;
                }
                date = date > diagnosisDate ? diagnosisDate : date;
            });

            return date;
        },
        maxConsentDate() {
            return this.consent.revoked_at ? new Date(this.consent.revoked_at) : new Date();
        },
        minRevokedDate() {
            return this.consent.consented_at ? new Date(this.consent.consented_at) : this.minConsentDate;
        },
        consentRules() { //Consent rules applies when status equals yes
            return this.consent.status == 1 ? "required" : "";
        },
        revokedRules() { //Rovoled rules applies when changing status from yes to no, or when revocation reason is filled in
            return this.consent.status == 0 && (this.data.status == 1 || (this.data.status == 0 && this.data.revocation_reason)) ? "required" : "";
        },
    },
    methods: {
        setSearchSelectWidth() {
            this.$nextTick(() => {
                this.searchSelectWidth = this?.$refs?.study_id?.$el?.clientWidth || null;
            });
        },
        name() {
            return this.data.registry != null ? this.data.registry.name : this.data.study.name;
        },
        save() {
            if (this.data.id) {
                this.$emit("consent-update", this.consent);
            } else {
                this.$emit("consent-add", this.consent);
            }
        },
        async load() {
            await this.$store.dispatch("meta/studies");

            this.consent.id = this?.data?.id || null;
            this.consent.status = this?.data?.status || null;
            this.consent.consented_at = this?.data?.consented_at || null;
            this.consent.consented_by = this?.data?.consented_by || null;
            this.consent.revocation_reason = this?.data?.revocation_reason || null;
            this.consent.revoked_at = this?.data?.revoked_at || null;

            if (this?.data?.status == 0) {
                this.consent.status = 0;
            }

            this.loaded = true;
        },
        close() {
            this.$emit("close");
        },
        consentDelete() {
            this.$emit("consent-delete", this.consent.id);
        },
        statusChange() {
            if (this.consent.status != 1) {
                this.consent.consented_at = null;
                this.consent.consented_by = null;
            } else if (this.consent.status != 0 || !this.data.revocation_reason) {
                this.consent.revocation_reason = null;
                this.consent.revoked_at = null;
            }
        },
    }
}
</script>