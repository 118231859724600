<template>
    <div class="h-full relative">
        <module-form-header :label="$t('patient.consent.consent') + ' - ' +  name()" :patient="info" :data="{}" />
        <div class="p-4">
            <consent-form
                :info="info"
                :data="data"
                @consent-update="consentUpdate"
                @consent-delete="consentDelete"
                @close="$modal.hide('consent-edit')"
            />
        </div>
    </div>
</template>

<script>
import ConsentForm from "./ConsentForm";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";

export default {
    name: "ConsentEdit",
    props: ["info", "data"],
    components: {
        ConsentForm,
        ModuleFormHeader
    },

    methods: {
        name() {
            return this.data.registry != null ? this.data.registry.name : this.data.study.name;
        },
        consentUpdate(consent) {
            this.$store
                .dispatch("patient/updateConsent", {
                    id: consent.id,
                    data : {
                        status: parseInt(consent.status),
                        consented_at: consent.consented_at,
                        consented_by: consent.consented_by,
                        revocation_reason: parseInt(consent.revocation_reason),
                        revoked_at: consent.revoked_at
                    }
                })
                .then(() => {
                    this.$modal.hide("consent-edit")
                });
        },
        consentDelete(id) {
            let buttons = [
                {
                    title: this.$t("button.cancel"),
                    handler: () => {
                        this.$modal.hide("dialog");
                    },
                },
                {
                    title: this.$t("button.continue"),
                    class: "vue-dialog-button bg-red-500 text-white hover:bg-red-600 transition ease-in-out duration-150",
                    handler: () => {
                        this.$modal.hide("dialog");
                        this.$store
                        .dispatch("patient/deleteConsent", {
                            id: id,
                        })
                        .then(() => {
                            this.$modal.hide("consent-edit");
                        });
                    },
                },
            ];
            this.$modal.show("dialog", {
                title: this.$t("patient.consent.dialog.delete.title"),
                text: this.$t("patient.consent.dialog.delete.text", {
                    'registry': this.name()
                }),
                buttons: buttons,
            });
        },
    }
}
</script>