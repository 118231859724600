<template>
  <div class="h-full relative" v-if="loaded">
    <module-form-header :label="$t('patient.consent.consent')" :patient="info" :data="{}" />

    <div class="p-4">
      <div class="flex" >
        <h3 class="font-semibold" v-if="registryConsents.length > 0">
          {{ $t("patient.consent.registry")}}
        </h3>
      </div>

      <div class="flex justify-between">
        <table class="f-full consent-table" v-if="registryConsents.length > 0">
          <!-- <tr>
            <td>{{ $t("patient.consent.registry") }}</td>
            <td>{{ $t("patient.consent.consent") }}</td>
            <td>{{ $t("patient.consent.consent_date") }}</td>
            <td>{{ $t("patient.consent.consent_name") }}</td>
          </tr> -->
          <tr v-for="(consent, key) in registryConsents" :key="key">
            <td>{{ name(consent) }}</td>
            <td>{{ status(consent.status) }}</td>
            <td>{{ consent.consented_at ? $dateFormat(consent.consented_at) : null }}</td>
            <td>{{ consent.consented_by }}</td>
            <td><cm-button
              size="small"
              @click="editConsent(consent)"
            >{{ $t("button.edit") }}</cm-button>
            </td>
          </tr>
        </table>
      </div>
          <div class="flex">
        <h3 class="font-semibold">
          {{ $t("patient.consent.study")}}
        </h3>
        <div class="pl-2" v-if="selectableStudies">
          <cm-button
            size="small"
            @click="$modal.show('consent-add')"
            >{{ $t("patient.consent.add") }}</cm-button>
        </div>
      </div>
      <div class="flex justify-between">
        <table class="f-full consent-table" v-if="studyConsents.length > 0">
          <!-- <tr>
            <td>{{ $t("patient.consent.study") }}</td>
            <td>{{ $t("patient.consent.consent") }}</td>
            <td>{{ $t("patient.consent.consent_date") }}</td>
            <td>{{ $t("patient.consent.consent_name") }}</td>
          </tr> -->
          <tr v-for="(consent, key) in studyConsents" :key="key">
            <td>{{ name(consent) }}</td>
            <td>{{ status(consent.status) }}</td>
            <td>{{ consent.consented_at ? $dateFormat(consent.consented_at) : null }}</td>
            <td>{{ consent.consented_by }}</td>
            <td><cm-button
              size="small"
              @click="editConsent(consent)"
            >{{ $t("button.edit") }}</cm-button>
            </td>
          </tr>
        </table>
      </div>

      <cm-button type="button" class="btn btn-white mr-2 focus:border-2 mt-3" @click="$modal.hide('patient-consent')">{{
          $t("button.close")
      }}</cm-button>

    </div>

    <modal
      name="consent-edit"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded-lg shadow flex flex-col justify-between"
    >
      <consent-edit :info="info" :data="consent"/>
    </modal>

    <modal
      name="consent-add"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded-lg shadow flex flex-col justify-between"
    >
      <consent-add :info="info"/>
    </modal>

  </div>
</template>

<script>
import { mapState } from "vuex";
import ConsentEdit from "@/components/patient/consent/ConsentEdit";
import ConsentAdd from "@/components/patient/consent/ConsentAdd";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";

export default {
  name: "PatientConsent",
  props: ["info"],
  components:{
    ConsentEdit,
    ConsentAdd,
    ModuleFormHeader
  },
  data() {
    return {
      loaded: false,
      consent: null
    }
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      await this.$store.dispatch("meta/studies");

      this.loaded = true;
    },
    name(consent) {
      return consent.registry !== null ? consent.registry.name : consent.study.name;
    },
    status(status) {
      switch(status) {
        case 0:
          return this.$t("patient.consent.options.status.no")
        case 1:
          return this.$t("patient.consent.options.status.yes")
        case 99:
          return this.$t("patient.consent.options.status.not_obtained");
      }

      return;
    },
    editConsent(consent) {
      this.consent = consent;
      this.$modal.show("consent-edit");
    },
  },
  computed: {
    ...mapState("meta", {
      studies: state => state.studies
    }),
    registryConsents() {
      return this.info.consents.filter(d => d.registry);
    },
    studyConsents() {
      return this.info.consents.filter(d => d.study);
    },
    selectableStudies() {
      //Check so that patient not have consent to all studies
      //todo add relation between registry and study
      let selectableStudies = false;
      let patientStudies = this.studyConsents.map( function(c) {
        return c.study.id;
      });

      this.studies.forEach(s => {
        if (patientStudies.indexOf(s.id) == -1) {
          selectableStudies = true;
        }
      });

      return selectableStudies;
    },
  },
};
</script>

<style scoped>
  .consent-table td {
    padding-right: 4px;
  }
</style>